import { ContentConfigurationCallToActionT, StaticContentT } from '../../model/StaticContent.ts';
import { Box, Flex, Icon, Link, Text } from '@chakra-ui/react';
import { Title } from '../../../commons/components/Title';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { MdChevronRight } from 'react-icons/md';

interface Props {
	content: StaticContentT;
}

export function ContentCallToAction({ content }: Props) {
	const conf = content.configuration as ContentConfigurationCallToActionT;
	return (
		<Box m='auto' w={{ base: '90%', sm: '90%', md: '90%', xl: '80%' }}>
			<Title title={content.title} description={content.description} />
			<Flex justifyContent='center' h={'100%'} mb={6}>
				<Flex flexDirection='column' width={{ base: '75%', lg: '60%' }} gap={[4]}>
					{conf.items.map((item, index) => (
						<Link key={item.label + index} href={item.reference} isExternal={conf.openInNewTab}>
							<Flex backgroundColor='red' width='100%' rounded='full' bgColor='buttonColor' boxShadow='md' py={4} px={8} justifyContent='space-between'>
								<Text
									fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }}
									color='buttonTextColor'
									justifySelf='center'
									wordBreak='break-word'
									textTransform='uppercase'
									fontWeight='bold'
								>
									{item.label}
								</Text>
								{conf.openInNewTab ? <Icon as={HiOutlineExternalLink} w={7} h={7} color='buttonTextColor' /> : <Icon as={MdChevronRight} w={7} h={7} color='buttonTextColor' />}
							</Flex>
						</Link>
					))}
				</Flex>
			</Flex>
		</Box>
	);
}
