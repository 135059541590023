function extractSVGPath(svgContent: string): string | null {
	// Parse the SVG content
	const parser = new DOMParser();
	const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');

	// Find the path element
	const pathElement = svgDoc.querySelector('path');

	// Extract and return the 'd' attribute value
	return pathElement ? pathElement.getAttribute('d') : null;
}

export async function extractSVGPathFromUrl(svgUrl: string) {
	try {
		const response = await fetch(svgUrl);
		const svgContent = await response.text();
		return extractSVGPath(svgContent) ?? '';
	} catch (error) {
		console.error('Error fetching or parsing SVG content:', error);
		return '';
	}
}
