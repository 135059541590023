import React, { useEffect } from 'react';
import { useToken } from '@chakra-ui/react';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import maxBy from 'lodash/maxBy';
import { BarChartConfiguration } from '../../model/Visualization.ts';
import { insertNewlineEveryAmountWords } from '../../../../util/StringUtil.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import { useBarChartData } from '../../hooks/useBarChartData.ts';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';

export const BarChartVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	const conf = visualization.configuration as BarChartConfiguration;
	const { data, isLoading } = useBarChartData(presentationId, visualization.id);

	const [successColor] = useToken('colors', ['greenSuccess']);

	const selectedAnswers = conf.selectedAnswers;
	const BarData = data?.map((barData) => ({
		category: truncate(barData.category, { length: 300 }),
		tooltip: insertNewlineEveryAmountWords(barData.category),
		value: barData.value,
		color: includes(selectedAnswers, barData.category) ? successColor : barData.color,
	}));

	BarData?.sort((a, b) => b.value - a.value);

	const maxValueInData = maxBy(BarData, 'value')?.value;

	useEffect(() => {
		const root = am5.Root.new('chartdiv');

		if (root && !isEmpty(BarData)) {
			root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
			const chart = root.container.children.push(
				am5xy.XYChart.new(root, {
					height: am5.percent(80),
				})
			);

			// Create axes
			const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 30 });
			xRenderer.labels.template.setAll({
				forceHidden: true,
				rotation: 0,
			});

			const xAxis = chart.xAxes.push(
				am5xy.CategoryAxis.new(root, {
					categoryField: 'category',
					renderer: xRenderer,
				})
			);

			const yAxis = chart.yAxes.push(
				am5xy.ValueAxis.new(root, {
					maxPrecision: 0,
					min: 0,
					max: maxValueInData,
					renderer: am5xy.AxisRendererY.new(root, {}),
				})
			);

			xAxis.data.setAll(BarData!);

			const series = chart.series.push(
				am5xy.ColumnSeries.new(root, {
					name: 'Series',
					xAxis: xAxis,
					yAxis: yAxis,
					categoryXField: 'category',
					valueYField: 'value',
				})
			);

			series.columns.template.setAll({
				cornerRadiusTL: 5,
				cornerRadiusTR: 5,
				strokeOpacity: 0,
				tooltipText: '{tooltip}: {value}',
			});

			series.data.setAll(BarData!);
			series.bullets.push(function () {
				return am5.Bullet.new(root, {
					locationX: 1,
					locationY: 0.5,
					sprite: am5.Label.new(root, {
						centerX: am5.p100,
						centerY: am5.p100,
						oversizedBehavior: 'wrap',
						text: '{category}',
						fill: am5.color(0xffffff),
						populateText: true,
					}),
				});
			});
			series.columns.template.adapters.add('fill', (fill, target) => {
				const dataItem = target.dataItem;
				if (dataItem) {
					//@ts-expect-error: error data type
					const color = dataItem.dataContext.color;
					return am5.color(color);
				}
				return fill;
			});

			// Add cursor
			chart.set('cursor', am5xy.XYCursor.new(root, {}));

			series.appear();
			chart.appear();
		}

		return () => {
			root.dispose();
		};
	}, [BarData]);

	return (
		<VisualizationChartWrapper
			title={visualization.title}
			description={visualization.description}
			isLoading={isLoading}
			isNoData={isEmpty(BarData)}
			reference={conf.reference}
			referenceColor={successColor}
		/>
	);
};
