import { useQuery } from '@tanstack/react-query';
import { getGaugeData } from '../service/VisualizationService.ts';

const key = (presentationId: string, visualizationId: string) => ['presentation', presentationId, 'visualization', visualizationId, 'gauge'];

export const useGaugeData = (presentationId: string, visualizationId: string) => {
	return useQuery({
		queryKey: key(presentationId, visualizationId),
		queryFn: () => getGaugeData(presentationId, visualizationId),
	});
};
