import get from 'lodash/get';
import { translations } from './translation-dictionary.ts';

type Language = keyof typeof translations;
type Translation = (typeof translations)['en']['translation'];
export type TranslationKeys = keyof Translation;

const getLanguage = (): Language => {
	const language = navigator.language;
	return language.toLowerCase().includes('es') ? 'es' : 'en';
};

export const translate = (key: TranslationKeys) => {
	const language = getLanguage();
	return get(translations, `${language}.translation.${key}`, key);
};
