import { createContext, KeyboardEvent, MutableRefObject, ReactNode, TouchEvent, useContext, useMemo, useRef, useState } from 'react';
import { PresentationT, SlideT } from '../../model/Presentation.ts';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';

interface SwipeProps {
	onTouchStart: (e: TouchEvent<HTMLDivElement>) => void;
	onTouchMove: (e: TouchEvent<HTMLDivElement>) => void;
	onTouchEnd: () => void;
}

interface KeyboardProps {
	onKeyDown: (event: KeyboardEvent<HTMLDivElement>) => void;
	tabIndex: 0;
}

interface SliderControl {
	refContainer: MutableRefObject<null>;
	presentation: PresentationT;
	currentSlide?: SlideT;
	currentSlideIndex: number;
	totalSlides: number;
	canGoBack: boolean;
	canGoForward: boolean;
	goBack: () => void;
	goForward: () => void;
	getSwipeProps: () => SwipeProps;
	getKeyboardProps: () => KeyboardProps;
}

const SliderControlsContext = createContext<SliderControl | undefined>(undefined);

export const useSliderControls = (): SliderControl => {
	const context = useContext(SliderControlsContext);
	if (context === undefined) {
		throw new Error('useSlider must be used within a SliderControlsProvider');
	}
	return context;
};

interface Props {
	currentSlideId?: string;
	presentation: PresentationT;
	children: ReactNode;
}

export const SliderControlsProvider = ({ currentSlideId, presentation, children }: Props) => {
	const navigate = useNavigate();
	const refContainer = useRef(null);

	// Swipe
	const minSwipeDistance = 50;
	const [touchStart, setTouchStart] = useState<number | null>(null);
	const [touchEnd, setTouchEnd] = useState<number | null>(null);
	// Swipe

	const totalSlides = get(presentation, 'slides.length', 0) - 1;
	const emptySlide: SlideT = {
		id: '',
		index: 0,
		hideTitle: false,
	};
	const currentSlide = presentation.slides.find((slide) => slide.id === currentSlideId) ?? emptySlide;
	const currentSlideArrayIndex = findIndex(presentation.slides, (slide) => slide.id === currentSlideId);
	const canGoBack = currentSlideArrayIndex > 0;
	const canGoForward = currentSlideArrayIndex < totalSlides;
	const doNavigate = (slideId: string) => {
		navigate(`/presentation/${presentation.id}/slides/${slideId}`);
	};

	const handleNextSlide = () => {
		if (canGoForward && presentation) {
			const { id } = presentation.slides[currentSlideArrayIndex + 1];
			doNavigate(id);
		}
	};

	const handleBackSlide = () => {
		if (canGoBack && presentation) {
			const { id } = presentation.slides[currentSlideArrayIndex - 1];
			doNavigate(id);
		}
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		const key = event.key;
		event.stopPropagation();
		const tabIndex = document.activeElement?.getAttribute('tabIndex');

		if (key === 'ArrowLeft' && tabIndex === '0') {
			handleBackSlide();
		} else if (key === 'ArrowRight' && tabIndex === '0') {
			handleNextSlide();
		}
	};

	// Swipe
	const onTouchStart = (e: TouchEvent<HTMLDivElement>) => {
		setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
		setTouchStart(e.targetTouches[0].clientX);
	};

	const onTouchMove = (e: TouchEvent<HTMLDivElement>) => setTouchEnd(e.targetTouches[0].clientX);

	const onTouchEnd = () => {
		if (!touchStart || !touchEnd) return;
		const distance = touchStart - touchEnd;
		const isLeftSwipe = distance > minSwipeDistance;
		const isRightSwipe = distance < -minSwipeDistance;
		if (isLeftSwipe) {
			handleNextSlide();
		} else if (isRightSwipe) {
			handleBackSlide();
		}
	};

	const getSwipeProps = (): SwipeProps => {
		return {
			onTouchStart,
			onTouchMove,
			onTouchEnd,
		};
	};

	const getKeyboardProps = (): KeyboardProps => {
		return {
			onKeyDown: handleKeyDown,
			tabIndex: 0,
		};
	};

	const value: SliderControl = useMemo(
		() => ({
			refContainer,
			presentation,
			currentSlide: currentSlide,
			currentSlideIndex: currentSlideArrayIndex,
			totalSlides,
			canGoBack,
			canGoForward,
			goBack: handleBackSlide,
			goForward: handleNextSlide,
			getSwipeProps,
			getKeyboardProps,
		}),
		[currentSlide, currentSlideArrayIndex, totalSlides, canGoBack, canGoForward]
	);

	return <SliderControlsContext.Provider value={value}>{children}</SliderControlsContext.Provider>;
};
