import ApiClient from '../../../apiClient';
import { PresentationT, SlideT } from '../model/Presentation.ts';

export const getPresentationById = async (presentationId: string): Promise<PresentationT> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}`)).data;
};

export const getSlideById = async (presentationId: string, slideId: string): Promise<SlideT> => {
	return (await ApiClient.getInstance().get(`/client-app/presentations/${presentationId}/slides/${slideId}`)).data;
};
