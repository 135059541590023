import React from 'react';
import { SlideT } from '../../model/Presentation.ts';
import { Interaction } from '../../../interaction/components/Interaction';
import { Visualization } from '../../../visualization/components/Visualization';
import { StaticContent } from '../../../staticContent/components/StaticContent';

interface Props {
	presentationId: string;
	slide: SlideT;
	onInteractionEnd: () => void;
}

export const Element: React.FC<Props> = ({ presentationId, slide, onInteractionEnd }) => {
	return (
		<>
			{slide?.interaction ? <Interaction presentationId={presentationId} interaction={slide.interaction} onInteractionEnd={onInteractionEnd} /> : null}
			{slide?.visualization ? <Visualization presentationId={presentationId} visualization={slide.visualization} /> : null}
			{slide.staticContent ? <StaticContent content={slide.staticContent} /> : null}
		</>
	);
};
