import React, { useEffect, useState } from 'react';
import { useUnsplashSearch } from '../../querHooks/useUnsplashSearch';
import { Box, Image, Input, Link, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import { unsplashClient, UnsplashPhoto, UnsplashSearchParams } from '../../api/UnsplashApi';
import { Loading } from '../../../commons/components/Loading';
import { AlertBox } from '../../../commons/components/AlertBox';

interface Props {
	onChange: (selectedImageUrl: UnsplashPhoto) => void;
}

export const UnsplashImagePicker: React.FC<Props> = ({ onChange }) => {
	const [query, setQuery] = useState<UnsplashSearchParams>({
		page: 1,
		query: '',
	});
	const { isLoading, data, isError } = useUnsplashSearch(query);
	const [hoveredPhotoId, setHoveredPhotoId] = useState<string | null>(null);

	const handleClick = (photo: UnsplashPhoto) => async () => {
		onChange(photo);
		await unsplashClient.trackPhotoDownload(photo);
	};

	// const handleGoBack = () => {
	// 	if (query.page > 1) {
	// 		setQuery({ ...query, page: query.page - 1 });
	// 	}
	// };
	//
	// const hadleGoForward = () => {
	// 	if (data && query.page <= data.total_pages) {
	// 		setQuery({ ...query, page: query.page + 1 });
	// 	}
	// };

	const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setQuery({ ...query, page: 1, query: event.target.value });
	};

	const debouncedInputChangeHandler = debounce(handleOnInputChange, 1200);

	useEffect(() => {
		return () => {
			debouncedInputChangeHandler.cancel();
		};
	}, []);

	if (isError) {
		return <AlertBox status='error' title='Error fetching from Unsplash' />;
	}

	return (
		<VStack>
			<Input placeholder='Search for an Image' autoComplete='off' m={4} onChange={debouncedInputChangeHandler} />
			{isLoading ? <Loading /> : null}
			{data && !isEmpty(data?.results) ? (
				<>
					<SimpleGrid columns={4} gap={8}>
						{data.results.map((photo) => {
							return (
								<Box position='relative' key={photo.id} p={2} onMouseEnter={() => setHoveredPhotoId(photo.id)} onMouseLeave={() => setHoveredPhotoId(null)}>
									<Image src={photo.urls.small} alt={photo.description ?? photo.user.name} onClick={handleClick(photo)} cursor='pointer' />
									{hoveredPhotoId === photo.id && (
										<Text m={4} fontSize='sm' color='white' position='absolute' bottom='5px' left='5px' backgroundColor='rgba(0, 0, 0, 0.8)' padding={2} borderRadius={4}>
											<Link color='white' href={`${photo.user.links.html}?utm_source=questiory&utm_medium=referral`} target='_blank' rel='nofollow noreferrer noopener'>
												Photo by: {photo.user.name}
											</Link>{' '}
											on{' '}
											<Link color='white' href='https://unsplash.com?utm_source=questiory&utm_medium=referral' target='_blank' rel='nofollow noreferrer noopener'>
												Unsplash
											</Link>
										</Text>
									)}
								</Box>
							);
						})}
					</SimpleGrid>
					{/*<Flex gap={2}>*/}
					{/*	<Button variant='outline' isDisabled={query.page === 1} isLoading={isLoading} onClick={handleGoBack}>*/}
					{/*		Back*/}
					{/*	</Button>*/}
					{/*	<Button variant='outline' isDisabled={query.page >= data?.total_pages} isLoading={isLoading} onClick={hadleGoForward}>*/}
					{/*		next*/}
					{/*	</Button>*/}
					{/*</Flex>*/}
				</>
			) : null}
		</VStack>
	);
};

UnsplashImagePicker.defaultProps = {};
