import React from 'react';
import { IconicInteraction } from '../IconicInteraction';
import { MultipleChoiceInteraction } from '../MultipleChoiceInteraction';
import { NumberInteraction } from '../NumberInteraction';
import { QuestionInteraction } from '../QuestionInteraction';
import { ValuationInteraction } from '../ValuationInteraction';
import { DrawingInteraction } from '../DrawingInteraction';
import { ImageInteraction } from '../ImageInteraction';
import { InteractionT, InteractionTypeT } from '../../model/Interaction.ts';
import { CategorisedInteraction } from '../CategorisedInteraction';
import { LinkInteraction } from '../LinkInteraction';
import { OpenEndedLongInteraction } from '../OpenEndedLongInteraction';

export interface InteractionProps {
	presentationId: string;
	interaction: InteractionT;
	onInteractionEnd: () => void;
}

export const Interaction: React.FC<InteractionProps> = (props) => {
	switch (props.interaction.type) {
		case InteractionTypeT.OPEN_ENDED:
			return <QuestionInteraction key={props.interaction.id} {...props} />;
		case InteractionTypeT.OPEN_ENDED_LONG:
			return <OpenEndedLongInteraction key={props.interaction.id} {...props} />;
		case InteractionTypeT.NUMBER:
			return <NumberInteraction key={props.interaction.id} {...props} />;
		case InteractionTypeT.MULTIPLE_CHOICE:
			return <MultipleChoiceInteraction key={props.interaction.id} {...props} />;
		case InteractionTypeT.ICONIC:
			return <IconicInteraction key={props.interaction.id} {...props} />;
		case InteractionTypeT.CATEGORISED:
			return <CategorisedInteraction key={props.interaction.id} {...props} />;
		case InteractionTypeT.VALUATION:
			return <ValuationInteraction key={props.interaction.id} {...props} />;
		case InteractionTypeT.DRAWING:
			return <DrawingInteraction key={props.interaction.id} {...props} />;
		case InteractionTypeT.IMAGE:
			return <ImageInteraction key={props.interaction.id} {...props} />;
		case InteractionTypeT.LINK:
			return <LinkInteraction key={props.interaction.id} {...props} />;
		default:
			return null;
	}
};
