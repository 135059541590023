import { MultipleChoiceConfigurationT } from '../../../../model/Interaction.ts';
import { hasSubmission, recordSubmission } from '../../../../service/InteractionSubmissionTracker.ts';
import { Button, Checkbox, Flex, Icon, IconButton, Input, InputGroup, InputRightElement, Text, useStyleConfig, VStack } from '@chakra-ui/react';
import { InteractionProps } from '../../../Interaction/Interaction.tsx';
import { useFieldArray, useForm } from 'react-hook-form';
import { InteractionWrapper } from '../../../InteractionWrapper';
import { InteractionContainer } from '../../../InteractionContainer';
import { CheckboxCard } from '../CheckboxCard';
import { validateMaxLength, validateRequired } from '../../../../validation/InteractionValidation.ts';
import { QUESTION_INTERACTION_ANSWER_MAX_LENGTH } from '../../../../../../util/Constants.ts';
import { translate } from '../../../../../../translate';
import { MdAdd, MdClose } from 'react-icons/md';
import isEmpty from 'lodash/isEmpty';
import { MultipleChoiceEntryInput, saveMultipleChoiceEntry } from '../../../../service/EntryService.ts';

interface FormValueType {
	multipleEntry: string[];
	multipleOtherEntry: string[];
}

export function MultipleResponse({ presentationId, interaction, onInteractionEnd }: InteractionProps) {
	const config = interaction.configuration as MultipleChoiceConfigurationT;
	const alreadySubmitted = hasSubmission(interaction.id);
	const styles = useStyleConfig('RadioCard');

	const {
		handleSubmit,
		register,
		formState: { isSubmitting, errors, isSubmitSuccessful },
		watch,
		control,
		reset,
		setValue,
	} = useForm<FormValueType>({
		defaultValues: {
			multipleEntry: [],
			multipleOtherEntry: [],
		},
	});

	const { fields, append, remove } = useFieldArray({
		//@ts-expect-error: Ignore
		name: 'multipleOtherEntry',
		control,
		rules: {
			required: false,
			maxLength: config.maxOthers,
		},
	});

	const multipleOtherEntry = watch('multipleOtherEntry');
	const calculateMaxAnswer = () => {
		if (config.allowOthers) {
			return config.multipleAnswer ? config.maxOthers : 1;
		} else {
			return 0;
		}
	};

	const canAdd = fields.length < calculateMaxAnswer();
	const handleAdd = () => {
		if (canAdd) {
			append('', { shouldFocus: true });
		}
	};

	const handleRemove = (index: number) => () => {
		remove(index);
	};

	const onSubmit = async (data: FormValueType) => {
		const entries: string[] = data.multipleEntry;
		const otherEntries: string[] = data.multipleOtherEntry.filter((s) => s);
		if (!isEmpty(entries) || !isEmpty(otherEntries)) {
			const newEntries: MultipleChoiceEntryInput[] = entries.map((value) => ({ value, isOther: false }));
			const newOtherEntries: MultipleChoiceEntryInput[] = otherEntries.map((value) => ({ value, isOther: true }));
			const data = [...newEntries, ...newOtherEntries];
			await saveMultipleChoiceEntry(presentationId, interaction.id, data);
			recordSubmission(interaction.id);
			reset();
			setValue('multipleEntry', []);
			setValue('multipleOtherEntry', []);
			console.log('salvado');
			onInteractionEnd();
		} else if (alreadySubmitted || !interaction.required) {
			onInteractionEnd();
		}
	};

	console.log(`Watcher: entry: ${watch('multipleEntry')}... other: ${watch('multipleOtherEntry')}`);
	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} isSubmitSuccessful={isSubmitSuccessful}>
			<InteractionContainer>
				{errors.multipleEntry ? (
					<Flex justifyContent='center' mb={4}>
						<Text color='red'>Pick an option</Text>
					</Flex>
				) : null}
				<VStack gap={4}>
					{config.options.map((option, index) => (
						<CheckboxCard
							key={index}
							option={option}
							{...register('multipleEntry', {
								validate: {
									required: (value) => {
										const multipleOtherEntry = watch('multipleOtherEntry').filter((s) => s && s.length > 0);
										if (multipleOtherEntry.length > 0) {
											return true;
										}
										return interaction.required && !alreadySubmitted ? validateRequired(value, 'Select an option') : undefined;
									},
								},
							})}
						/>
					))}
					{multipleOtherEntry.length > 0 ? (
						<>
							{fields.map((field, index) => (
								<Flex
									id={field.id}
									key={field.id}
									sx={{
										...styles,
										paddingTop: 2,
										paddingBottom: 1,
										paddingX: 2,
									}}
									width='100%'
									justifyContent='space-between'
									gap={1}
								>
									<InputGroup size='lg' key={field.id} mb={1} flexDirection='column' width='90%'>
										<Input
											id={field.id}
											maxLength={QUESTION_INTERACTION_ANSWER_MAX_LENGTH}
											autoComplete='off'
											autoFocus
											size='md'
											{...register(`multipleOtherEntry.${index}` as const, {
												validate: {
													maxLength: (value) => {
														return validateMaxLength(value, QUESTION_INTERACTION_ANSWER_MAX_LENGTH, translate('Field too long'));
													},
												},
											})}
										/>
										<InputRightElement width='0.5rem' px={6} onClick={handleRemove(index)}>
											<IconButton rounded='50' aria-label='Delete' size='md' fontWeight='bold' icon={<Icon color='brand' as={MdClose} />} />
										</InputRightElement>
									</InputGroup>
									<Flex marginRight='0.53rem'>
										<Checkbox
											alignContent='center'
											colorScheme='colorScheme'
											pointerEvents='none'
											isFocusable={false}
											isChecked={watch(`multipleOtherEntry.${index}`)?.length > 0}
											alignSelf='center'
											borderRadius='md'
											size='2xl'
											border='2px'
											borderColor='brand'
										/>
									</Flex>
								</Flex>
							))}
						</>
					) : null}
					{config.allowOthers && canAdd && (
						<Flex alignSelf='flex-end'>
							<Button variant='submit' leftIcon={<MdAdd />} onClick={handleAdd} my={2}>
								Add other
							</Button>
						</Flex>
					)}
				</VStack>
			</InteractionContainer>
		</InteractionWrapper>
	);
}
