import { Box } from '@chakra-ui/react';
import { StaticContentT } from '../../model/StaticContent.ts';
import { Title } from '../../../commons/components/Title';

interface Props {
	content: StaticContentT;
}

export function ContentCover({ content }: Props) {
	return (
		<Box m='auto' w={{ base: '90%', sm: '90%', md: '90%', xl: '80%' }} padding={4}>
			<Title title={content.title} description={content.description} titleSizes={['md', 'lg']} subtitleSizes={['xl', '3xl']} />
		</Box>
	);
}
