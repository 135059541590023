import React from 'react';
import { StaticContentT, StaticContentTypeT } from '../../model/StaticContent.ts';
import { SlideArrows } from '../../../presentation/components/SlideArrows';
import { ContentCover } from '../ContentCover';
import { ContentStatement } from '../ContentStatement';
import { ContentQuote } from '../ContentQuote';
import { ContentEmbed } from '../ContentEmbed';
import { ContentList } from '../ContentList';
import { ContentCallToAction } from '../ContentCallToAction';
import { Flex } from '@chakra-ui/react';

export interface StaticContentProps {
	content: StaticContentT;
}

export const StaticContent: React.FC<StaticContentProps> = ({ content }) => {
	const renderComponent = () => {
		switch (content.type) {
			case StaticContentTypeT.COVER: {
				return <ContentCover content={content} />;
			}
			case StaticContentTypeT.STATEMENT: {
				return <ContentStatement content={content} />;
			}
			case StaticContentTypeT.QUOTE: {
				return <ContentQuote content={content} />;
			}
			case StaticContentTypeT.EMBED: {
				return <ContentEmbed content={content} />;
			}
			case StaticContentTypeT.LIST: {
				return <ContentList content={content} />;
			}
			case StaticContentTypeT.CALL_TO_ACTION: {
				return <ContentCallToAction content={content} />;
			}
		}
	};
	return (
		<>
			<Flex as='section' h='93%' overflowY='auto'>
				{renderComponent()}
				{/*This empy flex allows the component elements to not go behing the arrows*/}
				<Flex id='empty-flex'></Flex>
				<SlideArrows />
			</Flex>
		</>
	);
};
