import React from 'react';
import { useParams } from 'react-router-dom';
import { usePresentation } from '../../modules/presentation/hooks/usePresentation.ts';
import { Loading } from '../../modules/commons/components/Loading';
import { AlertBox } from '../../modules/commons/components/AlertBox';
import { SliderControlsProvider } from '../../modules/presentation/components/SliderControlsProvider';
import { Slide } from '../../modules/presentation/components/Slide';

export const SlidePage: React.FC = () => {
	const { presentationId, currentSlide } = useParams();
	const { data, isLoading, isError } = usePresentation(presentationId!);

	if (isLoading) {
		return <Loading />;
	}

	if (isError) {
		return <AlertBox status='error' title={`Error fetching presentation: ${presentationId}`} />;
	}

	if (data) {
		return (
			<SliderControlsProvider currentSlideId={currentSlide} presentation={data}>
				<Slide />
			</SliderControlsProvider>
		);
	}

	return null;
};
