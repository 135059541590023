import { ContentConfigurationListT, StaticContentT } from '../../model/StaticContent.ts';
import { Box, Flex, List, ListIcon, ListItem, Text } from '@chakra-ui/react';
import { Title } from '../../../commons/components/Title';
import { FaCircle } from 'react-icons/fa';

interface Props {
	content: StaticContentT;
}

export function ContentList({ content }: Props) {
	const conf = content.configuration as ContentConfigurationListT;
	return (
		<Box m='auto' w={{ base: '90%', sm: '90%', md: '90%', xl: '80%' }} p={4}>
			<Title title={content.title} description={content.description} />
			<Flex justifyContent='center' h='70%' mb={4}>
				<Flex flexDirection='column' width={{ base: '75%', lg: '60%' }} gap={[4]}>
					<List spacing={4} ml={4}>
						{conf.labels.map((item, index) => (
							<ListItem key={item + index}>
								<Flex alignItems='center'>
									<ListIcon as={FaCircle} color='brand' fontSize='0.7rem' />
									<Text fontWeight='medium' fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }} wordBreak='break-word'>
										{item}
									</Text>
								</Flex>
							</ListItem>
						))}
					</List>
				</Flex>
			</Flex>
		</Box>
	);
}
