import React from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { Title } from '../../../commons/components/Title';
import { VisualizationT } from '../../model/Visualization.ts';
import { translate } from '../../../../translate';

interface Props {
	visualization: VisualizationT;
	isNoData: boolean;
	children: React.ReactNode;
}

export const VisualizationWrapper: React.FC<Props> = ({ visualization, isNoData, children }) => {
	return (
		<Flex as='section' h='93%' overflowY='auto'>
			<Box m='auto'>
				<Box mt={16} mx={16}>
					<Title title={visualization.title} description={visualization.description} />
				</Box>
				{isNoData ? (
					<Heading fontSize='2xl' mt={0} textAlign='center'>
						{translate('It seems there are no answers to this question yet.')}
					</Heading>
				) : null}
				{children}
			</Box>
		</Flex>
	);
};

VisualizationWrapper.defaultProps = {};
