import { useQuery } from '@tanstack/react-query';
import { getSlideById } from '../services/PresentationService.ts';

const key = (presentationId: string, slideId: string) => ['presentation', presentationId, 'slide', slideId];

export const useSlide = (presentationId: string, slideId: string) => {
	return useQuery({
		queryKey: key(presentationId, slideId),
		queryFn: () => getSlideById(presentationId, slideId),
	});
};
