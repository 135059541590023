import { ContentConfigurationEmbedT, StaticContentT } from '../../model/StaticContent.ts';
import { Box, Flex } from '@chakra-ui/react';
import { Title } from '../../../commons/components/Title';

interface Props {
	content: StaticContentT;
}

export function ContentEmbed({ content }: Props) {
	const conf = content.configuration as ContentConfigurationEmbedT;
	return (
		<>
			<Flex flexDirection='column' w='100%' h='100%' overflow='auto'>
				<Title title={content.title} description={content.description} />
				<Box as='iframe' height='100%' width='100%' src={conf.content} allowFullScreen></Box>
			</Flex>
		</>
	);
}
