import { Circle, Flex, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React from 'react';

interface Props {
	reference?: string;
	circleColor?: string;
}

export const SelectedAnwserReference: React.FC<Props> = ({ reference, circleColor }) => {
	return !isEmpty(reference) ? (
		<Flex alignItems='center' justifyContent='center' m={8} fontSize='xl'>
			{!isEmpty(circleColor) ? <Circle bgColor={circleColor} size='20px' mx={2} /> : null}
			<Text color='textColor'>{reference}</Text>
		</Flex>
	) : null;
};

SelectedAnwserReference.defaultProps = {
	reference: '',
};
