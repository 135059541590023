import { useQuery } from '@tanstack/react-query';
import { generateLinkPreview } from '../service/LinkPreviewService.ts';
import isEmpty from 'lodash/isEmpty';
import { isValidUrl } from '../../commons/util/UrlValidationUtils.ts';

const key = (url: string) => ['link-preview', url];

export const useLinkPreview = (url: string) => {
	return useQuery({
		queryKey: key(url),
		queryFn: () => generateLinkPreview(url),
		refetchOnWindowFocus: false,
		staleTime: Infinity,
		retry: 1,
		enabled: !isEmpty(url) && isValidUrl(url),
	});
};
