import React, { ChangeEvent, useState } from 'react';
import { Box, Checkbox, Flex, Text, useStyleConfig } from '@chakra-ui/react';

type CheckBoxInputProps = {
	option: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

export const CheckboxCard: React.FC<CheckBoxInputProps> = React.forwardRef<HTMLInputElement, CheckBoxInputProps>(({ option, ...props }, ref) => {
	const styles = useStyleConfig('RadioCard');
	const [isChecked, setIsChecked] = useState(false);
	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked);
		props.onChange && props.onChange(e);
	};
	return (
		<Flex as='label' sx={styles} onChange={handleOnChange} cursor='pointer' width='100%' gap={2}>
			<Box flex='1'>
				<Text fontWeight='medium' fontSize={{ base: 'lg', md: 'xl', lg: '2xl' }} justifySelf='center' wordBreak='break-word'>
					{option}
				</Text>
			</Box>
			<input type='checkbox' {...props} ref={ref} value={option} hidden />
			<Checkbox
				alignContent='center'
				colorScheme='colorScheme'
				pointerEvents='none'
				isFocusable={false}
				isChecked={isChecked}
				alignSelf='center'
				borderRadius='md'
				size='2xl'
				border='2px'
				borderColor='brand'
				onChange={handleOnChange}
				onBlur={props.onBlur}
			/>
		</Flex>
	);
});

CheckboxCard.displayName = 'CheckBoxInput'; // It's good practice to set a displayName when using forwardRef.
