import chunk from 'lodash/chunk';
import toLower from 'lodash/toLower';
import isEmpty from 'lodash/isEmpty';

//========================
export function insertNewlineEveryAmountWords(text: string, amountWords: number = 8): string {
	// Split the text into words
	const words = text.split(/\s+/);

	// Chunk the words array into groups of amountWords
	const chunks = chunk(words, amountWords);

	// Join each chunk with spaces and then join chunks with newline
	return chunks.map((chunk) => chunk.join(' ')).join('\n');
}

//========================
export function toLowercase(data?: string[]): string[] {
	return !isEmpty(data) ? data!.map(toLower) : [];
}
