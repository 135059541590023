import { Alert as ChakraAlert, AlertIcon, AlertProps, AlertTitle, Center } from '@chakra-ui/react';
import React from 'react';

interface Props extends AlertProps {
	title: string;
}

export const AlertBox: React.FC<Props> = ({ title, status, ...props }) => {
	return (
		<Center>
			<ChakraAlert status={status} {...props}>
				<AlertIcon />
				<AlertTitle>{title}</AlertTitle>
			</ChakraAlert>
		</Center>
	);
};

AlertBox.defaultProps = {};
