import { useToken } from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5radar from '@amcharts/amcharts5/radar';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import { useGaugeData } from '../../hooks/useGaugeData.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';

export const GaugeVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	// const conf = visualization.configuration as GaugeConfiguration;
	const [brand] = useToken('colors', ['brand']);
	const { data, isLoading } = useGaugeData(presentationId, visualization.id);

	const gaugeData = useMemo(() => {
		if (isEmpty(data)) {
			return [];
		}
		return data.entries.map((d) => {
			const color = !isEmpty(d.color) ? d.color : brand;
			return {
				category: d.key,
				value: d.entry,
				full: d.maxValue,
				columnSettings: {
					fill: am5.color(color),
				},
			};
		});
	}, [data, brand]); // Dependencies

	useEffect(() => {
		let root: am5.Root | undefined;

		if (!isEmpty(gaugeData) && !isEmpty(data)) {
			root = am5.Root.new('chartdiv');
			root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);

			// Create chart
			// https://www.amcharts.com/docs/v5/charts/radar-chart/
			const chart = root.container.children.push(
				am5radar.RadarChart.new(root, {
					height: am5.percent(80),
					panX: false,
					panY: false,
					wheelX: 'none',
					wheelY: 'none',
					innerRadius: am5.percent(20),
					startAngle: -90,
					endAngle: 180,
				})
			);

			// Add cursor
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Cursor
			const cursor = chart.set(
				'cursor',
				am5radar.RadarCursor.new(root, {
					behavior: 'zoomX',
				})
			);

			cursor.lineY.set('visible', false);

			// Create axes and their renderers
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_axes
			const xRenderer = am5radar.AxisRendererCircular.new(root, {
				//minGridDistance: 50
			});

			xRenderer.labels.template.setAll({
				radius: 10,
			});

			xRenderer.grid.template.setAll({
				forceHidden: true,
			});

			const xAxis = chart.xAxes.push(
				am5xy.ValueAxis.new(root, {
					renderer: xRenderer,
					min: data.minValue,
					max: data.maxValue,
					strictMinMax: true,
					numberFormat: "#'%'",
					tooltip: am5.Tooltip.new(root, {}),
				})
			);

			const yRenderer = am5radar.AxisRendererRadial.new(root, {
				minGridDistance: 20,
			});

			yRenderer.labels.template.setAll({
				centerX: am5.p100,
				fontWeight: '500',
				fontSize: 18,
				templateField: 'columnSettings',
			});

			yRenderer.grid.template.setAll({
				forceHidden: true,
			});

			const yAxis = chart.yAxes.push(
				am5xy.CategoryAxis.new(root, {
					categoryField: 'category',
					renderer: yRenderer,
				})
			);

			yAxis.data.setAll(gaugeData);

			// Create series
			// https://www.amcharts.com/docs/v5/charts/radar-chart/#Adding_series
			const series1 = chart.series.push(
				am5radar.RadarColumnSeries.new(root, {
					xAxis: xAxis,
					yAxis: yAxis,
					clustered: false,
					valueXField: 'full',
					categoryYField: 'category',
					fill: root.interfaceColors.get('alternativeBackground'),
				})
			);

			series1.columns.template.setAll({
				width: am5.p100,
				fillOpacity: 0.08,
				strokeOpacity: 0,
				cornerRadius: 20,
			});

			series1.data.setAll(gaugeData);

			const series2 = chart.series.push(
				am5radar.RadarColumnSeries.new(root, {
					xAxis: xAxis,
					yAxis: yAxis,
					clustered: false,
					valueXField: 'value',
					categoryYField: 'category',
				})
			);

			series2.columns.template.setAll({
				width: am5.p100,
				strokeOpacity: 0,
				tooltipText: '{category}: {valueX}%',
				cornerRadius: 20,
				templateField: 'columnSettings',
			});

			series2.data.setAll(gaugeData);

			// Animate chart and series in
			// https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
			series1.appear(1000);
			series2.appear(1000);
			chart.appear(1000, 100);
		}
		return () => {
			// Clean up on unmount
			root?.dispose();
		};
	}, [data, gaugeData]);

	return <VisualizationChartWrapper title={visualization.title} description={visualization.description} isLoading={isLoading} isNoData={isEmpty(gaugeData)} />;
};
