import ApiClient from '../../../apiClient';

export interface LinkPreview {
	title: string;
	description: string;
	images: LinkImage[];
}

interface LinkImage {
	url: string;
	width: number;
	height: number;
}

export const generateLinkPreview = async (url: string): Promise<LinkPreview> => {
	const axiosResponse = await ApiClient.getInstance().post('/client-app/link-preview', { url });
	return axiosResponse.data;
};
