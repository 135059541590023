import { Flex, Heading, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useSliderControls } from '../../../presentation/components/SliderControlsProvider/SliderControlsProvider.tsx';

interface Props {
	title: string;
	description?: string;
	titleSizes?: string[];
	subtitleSizes?: string[];
}

export const Title: React.FC<Props> = ({ title, description, titleSizes, subtitleSizes }) => {
	const { currentSlide } = useSliderControls();
	const shoudRenderNull = currentSlide?.hideTitle && isEmpty(description);

	if (shoudRenderNull) {
		return null;
	}

	return (
		<Flex flexDirection='column' id='title-subtitle' my={2} backdropFilter='blur(5px)' borderRadius='3xl' padding={4}>
			{!currentSlide?.hideTitle ? (
				<Heading size={titleSizes} textAlign='center' wordBreak='break-word' my={2}>
					{title}
				</Heading>
			) : null}
			{!isEmpty(description) ? (
				<Text fontSize={subtitleSizes} mb={4} textAlign='center' fontWeight='bold' wordBreak='break-word'>
					{description}
				</Text>
			) : null}
		</Flex>
	);
};

Title.defaultProps = {
	titleSizes: ['xs', 'sm'],
	subtitleSizes: ['lg', '2xl'],
};
