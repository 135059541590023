import { Box, Text } from '@chakra-ui/react';
import { Title } from '../../../commons/components/Title';
import { ContentConfigurationQuoteT, StaticContentT } from '../../model/StaticContent.ts';

interface Props {
	content: StaticContentT;
}

export function ContentQuote({ content }: Props) {
	const conf = content.configuration as ContentConfigurationQuoteT;
	return (
		<Box m='auto' w={{ base: '90%', sm: '90%', md: '90%', xl: '80%' }} padding={4}>
			<Title title={content.title} description={content.description} titleSizes={['md', 'lg']} subtitleSizes={['xl', '3xl']} />

			<Box borderLeft='4px' borderColor='brand' pl={4} ml={2}>
				<Text fontSize='3xl' as='em' whiteSpace='pre-wrap'>
					{conf.quote}
				</Text>
			</Box>
		</Box>
	);
}
