export interface StaticContentT {
	id: string;
	title: string;
	description: string;
	configuration: StaticContentConfigurationT;
	type: StaticContentTypeT;
}

export interface StaticContentConfigurationT {
	id: number;
}

export interface ContentConfigurationCoverT extends StaticContentConfigurationT {}

export interface ContentConfigurationStatementT extends StaticContentConfigurationT {
	statement: string;
}

export interface ContentConfigurationQuoteT extends StaticContentConfigurationT {
	quote: string;
}

export interface ContentConfigurationEmbedT extends StaticContentConfigurationT {
	content: string;
}

export interface ContentConfigurationListT extends StaticContentConfigurationT {
	labels: string[];
}

export interface ContentConfigurationCallToActionT extends StaticContentConfigurationT {
	openInNewTab: boolean;
	items: ConfigurationCallToActionItemT[];
}

export interface ConfigurationCallToActionItemT {
	label: string;
	reference: string;
}

export enum StaticContentTypeT {
	COVER = 'COVER',
	STATEMENT = 'STATEMENT',
	QUOTE = 'QUOTE',
	EMBED = 'EMBED',
	LIST = 'LIST',
	CALL_TO_ACTION = 'CALL_TO_ACTION',
}
