import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { usePresentation } from '../../modules/presentation/hooks/usePresentation.ts';
import { Loading } from '../../modules/commons/components/Loading';
import isEmpty from 'lodash/isEmpty';
import { AlertBox } from '../../modules/commons/components/AlertBox';

interface Props {}

export const PresentationPage: React.FC<Props> = () => {
	const { presentationId } = useParams();
	const { isPending, data, isError } = usePresentation(presentationId!);

	if (isPending) {
		return <Loading />;
	}

	if (isError) {
		return <AlertBox status='error' title={`Error fetching presentation ${presentationId}`} />;
	}

	if (data && isEmpty(data.slides)) {
		return <AlertBox status='info' title='No slides for this presentation' />;
	}

	return <Navigate to={`/presentation/${presentationId}/slides/${data?.slides[0].id}/`} />;
};
