import { useQuery } from '@tanstack/react-query';
import { getThemeByPresentationId } from '../service/ThemeService.ts';
import isEmpty from 'lodash/isEmpty';

const key = (presentationId: string) => ['presentation', presentationId, 'theme'];

export const useQuestioryTheme = (presentationId?: string) => {
	return useQuery({
		queryKey: key(presentationId!),
		queryFn: () => getThemeByPresentationId(presentationId!),
		staleTime: Infinity,
		enabled: !isEmpty(presentationId),
	});
};
