import React, { useEffect, useRef, useState } from 'react';
import { CategorisedInteractionConfigurationT } from '../../model/Interaction.ts';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { CategorisedEntry, saveCategorisedEntry } from '../../service/EntryService.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { Circle, Flex, Grid, GridItem, IconButton, Text, useStyleConfig } from '@chakra-ui/react';
import { CategorisedCategory } from './_/CategorisedCategory';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';
import { translate } from '../../../../translate';

type Categories = {
	value: string;
};

export interface Entry {
	entry: Record<string, Categories[]>;
}

export const CategorisedInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, onInteractionEnd }) => {
	const config = interaction.configuration as CategorisedInteractionConfigurationT;
	const [error, setError] = useState<string | undefined>();
	const alreadySubmitted = hasSubmission(interaction.id);
	const styles = useStyleConfig('Card');
	const borderRadius = (styles.container as Record<string, string>)?.borderRadius;
	const defaultValues = config.categories.reduce(
		(acc, value) => {
			acc[value.id] = [];
			return acc;
		},
		{} as Record<string, Categories[]>
	);
	const {
		register,
		handleSubmit,
		control,
		formState: { isSubmitting, isSubmitSuccessful },
		watch,
		reset,
	} = useForm<Entry>({
		defaultValues: {
			entry: defaultValues,
		},
		shouldFocusError: false,
	});

	const onSubmit = async (data: Entry) => {
		const actualEntries: CategorisedEntry[] = Object.entries(data.entry)
			//Filter all empty values
			.filter(([key, entry]) => {
				// Filter all empty categories
				const categoriesFilter = entry.filter((cat) => !isEmpty(cat.value));
				return key.trim() !== '' && !isEmpty(categoriesFilter);
			})
			.map(([key, entry]) => {
				const parsedKey = parseInt(key, 10);
				const foundStatement = config.categories.find((cat) => cat.id === parsedKey);
				const values = entry.filter((e) => !isEmpty(e.value.trim())).map((e) => e.value);
				return {
					key: foundStatement!.category, // Forcing uncheck given that we will always find a statement
					entries: values,
				};
			})
			// However in the strange case that a statement was not found, we filter it
			.filter((value) => !isEmpty(value.key) && !isEmpty(value.entries));
		if (isEmpty(actualEntries) && interaction.required && !alreadySubmitted) {
			setError(translate('Add one response'));
		} else if (!isEmpty(actualEntries)) {
			await saveCategorisedEntry(presentationId, interaction.id, actualEntries);
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !interaction.required) {
			onInteractionEnd();
		}
	};

	const gridRef = useRef<HTMLDivElement>(null);

	const scroll = (direction: 'left' | 'right') => {
		if (gridRef.current) {
			const { scrollLeft, clientWidth } = gridRef.current;
			const scrollTo = direction === 'left' ? scrollLeft - clientWidth : scrollLeft + clientWidth;
			gridRef.current.scrollTo({ left: scrollTo, behavior: 'smooth' });
		}
	};

	useEffect(() => {
		if (gridRef.current) {
			gridRef.current.scrollTo({ left: 0, behavior: 'instant' });
		}
	}, []);

	const navigationDisplayArrow = {
		base: config.categories.length > 1 ? 'inline-flex' : 'none', // Default display on smaller screens
		md: config.categories.length <= 2 ? 'none' : 'inline-flex', // "none" for md and above if condition is met
		lg: config.categories.length <= 3 ? 'none' : 'inline-flex', // "none" for md and above if condition is met
	};

	const gridJustifyContent = {
		base: 'initial',
		md: config.categories.length <= 2 ? 'center' : 'initial',
		lg: config.categories.length <= 3 ? 'center' : 'initial',
	};

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} isSubmitSuccessful={isSubmitSuccessful}>
			{!isEmpty(error) ? (
				<Text color={'red'} textAlign='center'>
					{error}
				</Text>
			) : undefined}
			<Flex /*flexDirection='column'*/ alignItems='center' bg='#ffffff7d' borderRadius={borderRadius} mx={[4, null]}>
				<IconButton my={0} aria-label='Scroll left' icon={<MdOutlineArrowBackIos />} onClick={() => scroll('left')} color='buttonColor' display={navigationDisplayArrow} />
				{/*<Flex display={navigationDisplayArrow}>*/}
				{/*	<IconButton my={0} aria-label='Scroll left' icon={<MdOutlineArrowBackIos />} onClick={() => scroll('left')} color='brand' />*/}
				{/*	<IconButton my={0} aria-label='Scroll right' icon={<MdOutlineArrowForwardIos />} onClick={() => scroll('right')} color='brand' />*/}
				{/*</Flex>*/}
				<Grid
					ref={gridRef}
					id='grid'
					gap='1rem'
					gridAutoColumns={['100%', null, '48%', '32%']}
					width='100%'
					gridAutoFlow='column'
					overflowX='hidden'
					my={8}
					mx={4}
					justifyContent={gridJustifyContent}
					sx={{
						marginInlineStart: '1rem',
						marginInlineEnd: '1rem',
						scrollSnapType: 'x mandatory',
					}}
				>
					{config.categories.map((category) => (
						<GridItem
							key={category.id}
							as={Flex}
							flexDirection='column'
							scrollSnapAlign='start'
							p={4}
							m={2}
							borderRadius={borderRadius}
							boxShadow='md'
							bgColor='cardBackgroundColor'
						>
							<Flex alignItems='center'>
								<Circle bgColor={category.color} size='3' mr={2} />
								<Text as='h2' size='1' fontWeight='bold' wordBreak='break-word'>
									{category.category}
								</Text>
							</Flex>
							<CategorisedCategory control={control} register={register} watch={watch} interaction={interaction} categoryEntry={category} />
						</GridItem>
					))}
				</Grid>
				<IconButton my={0} aria-label='Scroll right' icon={<MdOutlineArrowForwardIos />} onClick={() => scroll('right')} color='buttonColor' display={navigationDisplayArrow} />
			</Flex>
		</InteractionWrapper>
	);
};

CategorisedInteraction.defaultProps = {};
