import React, { useEffect } from 'react';

interface Props {}

export const NoMatchPage: React.FC<Props> = () => {
	useEffect(() => {
		window.location.replace('https://questiory.com/examples');
	}, []);

	return null;
};

NoMatchPage.defaultProps = {};
