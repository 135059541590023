import { useQuery } from '@tanstack/react-query';
import { getPresentationById } from '../services/PresentationService.ts';

const key = (presentationId: string) => ['presentation', presentationId];

export const usePresentation = (presentationId: string) => {
	return useQuery({
		queryKey: key(presentationId),
		queryFn: () => getPresentationById(presentationId),
	});
};
