import { Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { SimpleMathConfiguration } from '../../model/Visualization.ts';
import { useSimpleMathEntries } from '../../hooks/useNumberData.ts';
import { Loading } from '../../../commons/components/Loading';
import ceil from 'lodash/ceil';
import { VisualizationWrapper } from '../VisualizationWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';

export const AverageVisualization: React.FC<VisualizationProps> = ({ presentationId, visualization }) => {
	const conf = visualization.configuration as SimpleMathConfiguration;
	const { data, isLoading } = useSimpleMathEntries(presentationId, visualization.id);

	if (isLoading) {
		return <Loading />;
	}

	const noData = !isLoading && (!data || !data.value);
	return (
		<VisualizationWrapper visualization={visualization} isNoData={noData}>
			{!noData ? (
				<Flex flexDirection='column' gap={2} alignItems='center' justifyContent='center' fontSize={30}>
					<Text fontSize='6xl'>{ceil(data!.value).toLocaleString()}</Text>
					<Text fontSize='md' mx={16} textAlign='center' wordBreak='break-word'>
						{conf.reference}
					</Text>
				</Flex>
			) : null}
		</VisualizationWrapper>
	);
};
