import React from 'react';
import { Flex } from '@chakra-ui/react';

interface Props {
	children: React.ReactNode;
}

export const InteractionContainer: React.FC<Props> = ({ children }) => {
	return (
		<Flex justifyContent='center'>
			<Flex flexDirection='column' width={{ base: '75%', lg: '60%' }}>
				{children}
			</Flex>
		</Flex>
	);
};

InteractionContainer.defaultProps = {};
