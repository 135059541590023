import { Center, Spinner } from '@chakra-ui/react';
import React from 'react';

interface Props {}

export const Loading: React.FC<Props> = () => {
	return (
		<Center justifyContent='center'>
			<Spinner />
		</Center>
	);
};
