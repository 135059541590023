import React from 'react';
import { QuestioryThemeProvider } from '../../../../theme/components/QuestioryThemeProvider';
import { GoogleAnalytics } from '../../GoogleAnalytics';

interface Props {
	children: React.ReactNode;
}

export const Layout: React.FC<Props> = ({ children }) => {
	return (
		<QuestioryThemeProvider>
			<GoogleAnalytics />
			{children}
		</QuestioryThemeProvider>
	);
};
