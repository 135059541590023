import { useQuery } from '@tanstack/react-query';
import { getNodesData } from '../service/VisualizationService.ts';

const key = (presentationId: string, visualizationId: string) => ['presentation', presentationId, 'visualization', visualizationId, 'nodes'];

export const useNodesData = (presentationId: string, visualizationId: string) => {
	return useQuery({
		queryKey: key(presentationId, visualizationId),
		queryFn: () => getNodesData(presentationId, visualizationId),
	});
};
