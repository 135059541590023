import { Button, Flex, FormControl, FormErrorMessage, Image, Input, Text, Textarea, useDisclosure } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { TbPlus } from 'react-icons/tb';
import { ImageInteractionConfigurationT } from '../../model/Interaction.ts';
import { usePreviewPageInfo } from '../../../../pages/PreviewPage/PreviewPage.tsx';
import { saveImageEntry } from '../../service/EntryService.ts';
import { ImagePicker } from '../ImagePicker';
import { InteractionWrapper } from '../InteractionWrapper';
import { InteractionContainer } from '../InteractionContainer';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { translate } from '../../../../translate';
import { validateMaxLength, validateMinLength, validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';

interface FormValueType {
	heading: string;
	text: string;
	imageUrl: string;
}

export const ImageInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, onInteractionEnd }) => {
	const config = interaction.configuration as ImageInteractionConfigurationT;
	const alreadySubmitted = hasSubmission(interaction.id);
	const {
		register,
		handleSubmit,
		control,
		formState: { isSubmitting, errors, isSubmitSuccessful },
		setFocus,
		watch,
		reset,
	} = useForm<FormValueType>({
		defaultValues: {
			heading: '',
			text: '',
			imageUrl: '',
		},
	});

	const { shouldAutoFocus } = usePreviewPageInfo();

	useEffect(() => {
		if (shouldAutoFocus) {
			setFocus('heading');
		}
	}, [setFocus, shouldAutoFocus]);

	const { isOpen, onOpen, onClose } = useDisclosure();

	const onSubmit: SubmitHandler<FormValueType> = async (data) => {
		if (!isEmpty(data.heading) && !isEmpty(data.imageUrl)) {
			await saveImageEntry(presentationId, interaction.id, { ...data });
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !interaction.required) {
			onInteractionEnd();
		}
	};

	const values = watch();
	const hasData = !isEmpty(values.heading) || !isEmpty(values.imageUrl);
	// const imageUrlWatch = watch('imageUrl');

	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} isSubmitSuccessful={isSubmitSuccessful}>
			<InteractionContainer>
				<Flex flexDirection='column' alignItems='center'>
					{!isEmpty(values.imageUrl) ? <Image src={values.imageUrl} boxSize='xs' fit='contain' onClick={onOpen} _hover={{ cursor: 'pointer' }} /> : null}
					<Controller
						name='imageUrl'
						control={control}
						rules={{
							validate: {
								required: (value) => {
									return (interaction.required && !alreadySubmitted) || hasData ? validateRequired(value, 'Select an image') : undefined;
								},
							},
						}}
						render={({ field, fieldState }) => (
							<>
								{isEmpty(values.imageUrl) ? (
									<Flex flexDirection='column' alignItems='center'>
										<Button variant='brand' background={fieldState.error?.type ? 'red' : undefined} rightIcon={<TbPlus />} onClick={onOpen}>
											{translate('Select image')}
										</Button>
										{fieldState.error && <Text color='red'>{fieldState.error.message}</Text>}
									</Flex>
								) : null}
								<ImagePicker isOpen={isOpen} onClose={onClose} onChange={field.onChange} />
							</>
						)}
					/>

					<FormControl isInvalid={!isEmpty(errors.heading)}>
						<Input
							size='lg'
							my={2}
							autoFocus={shouldAutoFocus}
							autoComplete='off'
							{...register('heading', {
								validate: {
									required: (value) => {
										return (interaction.required && !alreadySubmitted) || hasData ? validateRequired(value, translate('Field required')) : undefined;
									},
									minLength: (value) => {
										return validateMinLength(value, 3, translate('Field too short'));
									},
									maxLength: (value) => {
										return validateMaxLength(value, 500, translate('Field too long'));
									},
								},
							})}
						/>
						{errors.heading && <FormErrorMessage>{errors.heading.message}</FormErrorMessage>}
					</FormControl>
					{config.longAnswer ? (
						<FormControl isInvalid={!isEmpty(errors.text)}>
							<Textarea
								rows={8}
								my={2}
								autoComplete='off'
								{...register('text', {
									validate: {
										minLength: (value) => {
											return validateMinLength(value, 3, translate('Field too short'));
										},
										maxLength: (value) => {
											return validateMaxLength(value, 500, translate('Field too long'));
										},
									},
								})}
							/>
							{errors.text && <FormErrorMessage>{errors.text.message}</FormErrorMessage>}
						</FormControl>
					) : null}
				</Flex>
			</InteractionContainer>
		</InteractionWrapper>
	);
};

ImageInteraction.defaultProps = {};
