import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { usePreviewPageInfo } from '../../../../pages/PreviewPage/PreviewPage.tsx';
import { saveNumberEntry } from '../../service/EntryService.ts';
import { NumberInteractionConfigurationT } from '../../model/Interaction.ts';
import { InteractionWrapper } from '../InteractionWrapper';
import { InteractionContainer } from '../InteractionContainer';
import { InteractionProps } from '../Interaction/Interaction.tsx';
import { validateRequired } from '../../validation/InteractionValidation.ts';
import { hasSubmission, recordSubmission } from '../../service/InteractionSubmissionTracker.ts';
import { translate } from '../../../../translate';

interface FormValueType {
	entry: number;
}

export const NumberInteraction: React.FC<InteractionProps> = ({ presentationId, interaction, onInteractionEnd }) => {
	const config = interaction.configuration as NumberInteractionConfigurationT;
	const alreadySubmitted = hasSubmission(interaction.id);
	const {
		register,
		handleSubmit,
		formState: { isSubmitting, errors, isSubmitSuccessful },
		setFocus,
		reset,
	} = useForm<FormValueType>();

	const { shouldAutoFocus } = usePreviewPageInfo();

	useEffect(() => {
		if (shouldAutoFocus) {
			setFocus('entry');
		}
	}, [setFocus, shouldAutoFocus]);

	const onSubmit: SubmitHandler<FormValueType> = async (data) => {
		if (!isEmpty(data.entry)) {
			await saveNumberEntry(presentationId, interaction.id, data.entry);
			recordSubmission(interaction.id);
			reset();
			onInteractionEnd();
		} else if (alreadySubmitted || !interaction.required) {
			onInteractionEnd();
		}
	};
	return (
		<InteractionWrapper interaction={interaction} onSubmit={handleSubmit(onSubmit)} isSubmitting={isSubmitting} isSubmitSuccessful={isSubmitSuccessful}>
			<InteractionContainer>
				<FormControl isInvalid={!isEmpty(errors.entry)}>
					<Input
						type='number'
						min={config.minValue}
						max={config.maxValue}
						autoComplete='off'
						inputMode='numeric'
						size='lg'
						{...register(`entry`, {
							validate: {
								required: (value) => {
									return interaction.required && !alreadySubmitted ? validateRequired('' + value, translate('Field required')) : undefined;
								},
								min: (value) => {
									return !isEmpty(value) && +value < config.minValue ? 'Too low' : undefined;
								},
								max: (value) => {
									return !isEmpty(value) && +value > config.maxValue ? 'Too high' : undefined;
								},
							},
						})}
					/>
					{errors.entry && <FormErrorMessage>{errors.entry.message}</FormErrorMessage>}
				</FormControl>
			</InteractionContainer>
		</InteractionWrapper>
	);
};
