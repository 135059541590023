import { useToken } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Responsive from '@amcharts/amcharts5/themes/Responsive';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import flatMap from 'lodash/flatMap';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import truncate from 'lodash/truncate';
import { BlocksConfiguration } from '../../model/Visualization.ts';
import { useBlocksData } from '../../hooks/useBlocksData.ts';
import { insertNewlineEveryAmountWords } from '../../../../util/StringUtil.ts';
import { BlocksEntry } from '../../service/VisualizationService.ts';
import { am5 } from '../../../commons/armcharts/AmChartsSetup.ts';
import { VisualizationChartWrapper } from '../VisualizationChartWrapper';
import { VisualizationProps } from '../Visualization/Visualization.tsx';

export const BlocksVisualization: React.FC<VisualizationProps> = ({ visualization, presentationId }) => {
	const conf = visualization.configuration as BlocksConfiguration;
	const [brand] = useToken('colors', ['brand']);
	const { data, isLoading } = useBlocksData(presentationId, visualization.id);

	useEffect(() => {
		const root = am5.Root.new('chartdiv');

		const grouped = groupBy(data, 'key');
		const wheelData = map(grouped, (items, key) => {
			const color = find(data, { key })?.color || brand;
			const entries = Array.from(new Set(flatMap(items, 'entries'))); //Remove duplicates - Need to count instead of remove
			return {
				name: key,
				truncatedName: truncate(key, {
					length: calculateTruncatedLengthParent(data),
				}),
				textWithLineBreaks: insertNewlineEveryAmountWords(key),
				nodeSettings: { fill: am5.color(color) },
				children: entries.map((entry) => ({
					name: entry,
					truncatedName: truncate(entry, {
						length: calculateTruncatedLengthChild(data),
					}),
					textWithLineBreaks: insertNewlineEveryAmountWords(entry),
					nodeSettings: { fill: am5.color(color) },
					value: 1,
				})),
			};
		});

		if (root && !isEmpty(wheelData)) {
			root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);

			const chart = root.container.children.push(
				am5.Container.new(root, {
					width: am5.percent(100),
					height: am5.percent(100),
				})
			);

			// Create series
			// https://www.amcharts.com/docs/v5/charts/hierarchy/#Adding

			const series = chart.children.push(
				am5hierarchy.Partition.new(root, {
					singleBranchOnly: false,
					orientation: 'horizontal',
					initialDepth: 2,
					downDepth: 3,
					topDepth: 1,
					valueField: 'value',
					categoryField: 'truncatedName',
					childDataField: 'children',
					tooltip: am5.Tooltip.new(root, {
						forceHidden: true,
					}),
				})
			);

			series.labels.template.setAll({
				oversizedBehavior: 'wrap',
				text: '{truncatedName}',
			});

			series.rectangles.template.setAll({
				templateField: 'nodeSettings',
				tooltipText: '{textWithLineBreaks}',
			});

			const data = {
				truncatedName: conf.chartTitle ? insertNewlineEveryAmountWords(conf.chartTitle, 4) : '',
				nodeSettings: { fill: am5.color(brand) },
				children: [...wheelData],
			};

			series.data.setAll([data]);
			series.set('selectedDataItem', series.dataItems[0]);
			// Make stuff animate on load
			series.appear(1000, 100);
		}
		return () => {
			// Clean up on unmount
			root.dispose();
		};
	}, [data]);

	return <VisualizationChartWrapper title={visualization.title} description={visualization.description} isLoading={isLoading} isNoData={isEmpty(data)} />;
};

function calculateTruncatedLengthParent(items?: BlocksEntry[]) {
	const itemCount = items ? items.length : 0;
	if (itemCount <= 3) return 100;
	else if (itemCount <= 5) return 64;
	else if (itemCount <= 10) return 100;
	else if (itemCount <= 15) return 100;
	else return 40;
}

function calculateTruncatedLengthChild(items?: BlocksEntry[]) {
	const itemCount = items && !isEmpty(items) ? items.reduce((sum, obj) => sum + obj.entries.length, 0) : 0;
	if (itemCount <= 3) return 240;
	else if (itemCount <= 5) return 200;
	else if (itemCount <= 10) return 160;
	else if (itemCount <= 20) return 140;
	else if (itemCount <= 30) return 80;
	else if (itemCount <= 40) return 160;
	else return 80;
}
