import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { sentrySetup } from './modules/commons/sentry/Sentry.ts';

sentrySetup();

ReactDOM.createRoot(document.getElementById('root')!).render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
