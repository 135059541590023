import { InteractionT } from '../model/Interaction.ts';

const submissions = new Map<InteractionT['id'], undefined>();

export function recordSubmission(interactionId: InteractionT['id']): void {
	submissions.set(interactionId, undefined);
}

export function hasSubmission(interactionId: InteractionT['id']): boolean {
	// console.log('all submissions', { interactionId, submissions, has: submissions.has(interactionId) });
	return submissions.has(interactionId);
}
