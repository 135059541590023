import { Box, Flex, Heading } from '@chakra-ui/react';
import { Title } from '../../../commons/components/Title';
import { Loading } from '../../../commons/components/Loading';
import { SelectedAnwserReference } from '../SelectedAnwserReference';
import React from 'react';
import { translate } from '../../../../translate';

interface Props {
	title: string;
	description: string;
	isLoading: boolean;
	isNoData: boolean;
	reference?: string;
	referenceColor?: string;
}

export const VisualizationChartWrapper: React.FC<Props> = ({ title, description, isLoading, isNoData, reference, referenceColor }) => {
	return (
		<Flex id='chart-wrapper' flexDirection='column' as='section' h='90%' overflowY='auto'>
			<Box m={!isLoading && isNoData ? 'auto' : '6'} mb={!isNoData ? 0 : undefined}>
				<Box mx={16}>
					<Title title={title} description={description} />
				</Box>
				<SelectedAnwserReference reference={reference} circleColor={referenceColor} />
				{isLoading ? <Loading /> : null}
				{!isLoading && isNoData ? (
					<Heading fontSize='2xl' mt={0} textAlign='center'>
						{translate('It seems there are no answers to this question yet.')}
					</Heading>
				) : null}
			</Box>
			<Box id='chartdiv' height='47rem' mx={16} />
		</Flex>
	);
};

VisualizationChartWrapper.defaultProps = {};
